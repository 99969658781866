import React from 'react';
import styled from "styled-components"
import CiatLogo from "./ciat"
import FooterTitleLoop from './titles/FooterTitleLoop'

const FooterContainer = styled.footer`
	display: flex;
	flex-direction:column;
	background: #000;
	padding:0.8rem;
	margin: 0 2rem;
	min-height:110px;
`
const Wrapper = styled.div`
	display: flex;
	justify-content:space-between;
	width:100%;
	min-height:100px;
	border-top:solid 2px ${props => props.theme.red};
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column;
	}
`;

const CIATGroup = styled.div`
	display:flex;
	margin:0.8rem 0.4rem;
	width:50%;
	padding:0 0.8rem;
	align-items:center;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%;
	}
`;

const DetailsGroup = styled.div`
	display:flex;
	flex-direction:column;
	margin:0.8rem 0.4rem;
	max-width:50%;
	padding:0 0.8rem;
	justify-content:center;
	.details, .email{
		text-align:right;
		max-width:100%;
	}
	@media only screen and (${props => props.theme.mobileThreshold}) {
		max-width:100%;
		.details, .email{
			text-align:center;
		}
	}
`;

const GroupContent = styled.div`
	max-width:250px;
`;
const PASC = styled.div`
	/*  */
`;
const CIAT = styled.div`
	display:block;
	width:100%;
	max-width:250px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%;
		margin:0 auto;
	}
`;
const FooterTitle = styled.h3`
	margin:0.6rem 0;
	text-transform:uppercase;
	color:${props => props.theme.red};
	line-height:2.8rem;
	font-size:2.2rem;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		display:block;
		width:100%;
		text-align:center;
	}
`;
const WhiteFooterTitle = styled.h3`
	margin:0.6rem 0;
	text-transform:uppercase;
	color:${props => props.theme.red};
	line-height:1.6rem;
	font-size:1.2rem;
	color:#fff;
`;
const FooterFooter = styled.div`
	margin:0.8 0.4rem;
	display:flex;
	justify-content:center;
	align-items:center;
	font-size:1.1rem;
	color:${props => props.theme.grey};
`;
const FooterHeader = styled.div`
	margin:0.8 0.4rem;
	display:flex;
	justify-content:center;
	align-items:center;
	border-top:solid 2px ${props => props.theme.red};
`;


const EmailLink = styled.a`
	color:${props => props.theme.red};
	transition:${props => props.theme.trans};
	&:hover{
		color:${props => props.theme.grey};
	}
`;
const footer = () => {
	return (
		<FooterContainer>
			<FooterTitleLoop />
			<Wrapper>
				
				<CIATGroup>
					<CIAT>
						<CiatLogo />
					</CIAT>
				</CIATGroup>
				
				<DetailsGroup>
					<GroupContent className="details">
						<WhiteFooterTitle>
							Chartered Architectural Technologist
						</WhiteFooterTitle>
						<WhiteFooterTitle>
							Interior Architectural Designer
						</WhiteFooterTitle>
						<WhiteFooterTitle>
							Accredited Energy Assessor
						</WhiteFooterTitle>
					</GroupContent>
					<GroupContent className="email">
						<div>
							<span>Email: <EmailLink href="mailto:neil@boxas.co.uk" title="Email: neil@boxas.co.uk">neil@boxas.co.uk</EmailLink></span>
						</div>
					</GroupContent>
					<GroupContent className="email">
						<div>
							<span>Telephone:: <EmailLink href="tel:+447516743649" title="Telephone: 07516 743 649">07516 743 649</EmailLink></span>
						</div>
					</GroupContent>
				</DetailsGroup>

			</Wrapper>
			<FooterFooter>
					© BOXAS {new Date().getFullYear()}
			</FooterFooter>
		</FooterContainer>
	);
};

export default footer;