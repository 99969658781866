import React, { Component } from 'react';
import styled from "styled-components"


const FooterHeader = styled.div`
	margin:0.8 0.4rem;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
	border-top:solid 2px ${props => props.theme.red};
	position:relative;
	overflow:hidden;
`;

const FooterTitle = styled.h3`
	margin:0.6rem auto;
	text-transform:uppercase;
	color:${props => props.theme.red};
	line-height:2.8rem;
	font-size:2.2rem;
	position:relative;
	z-index:5;
	
	width:100%;
	text-align:center;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 350ms;
	transition-property: top, opacity;
	&.show{
		opacity:1;
		top:0;
	}
	&.hide{
		opacity:0;
		top:-20px;
	}
	&.out{
		opacity:0;
		top:20px;
	}
`;
const FooterTitleBlack = styled(FooterTitle)`
	color:#000;
	position:absolute;
	z-index:7;
`;
const FooterRed = styled.div`
	display:flex;
	position:absolute;
	z-index:6;
	width:100%;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 500ms;
	transition-property: top, opacity;
	background-color:${props => props.theme.red};
	min-height:100%;
	height:100%;
	&.show{
		transition-delay: 250ms;
		opacity:1;
		top:0;
	}
	&.hide{
		opacity:0;
		top:-50px;
	}
	&.out{
		transition-delay: 500ms;
		opacity:0;
		top:50px;
	}
`;

class FooterTitleLoop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
		};
	}

	componentDidMount() {
    this.int = setInterval(() => {
			const c = this.state.count >= 10 ? 0 : this.state.count + 1
			this.setState({
				count:c
			})
		}, 1500);
  }
	componentWillUnmount() {
    clearInterval(this.int);
	}
	render() {
		const { count } = this.state;
		return (
			<FooterHeader>
				<FooterTitle 
					className={count >= 1 && count <= 4 ? "show" 
						: count === 5 ? "out"
						: "hide"
					}
				>
					Planning, Architectural & SAP&nbsp;Consultant
				</FooterTitle>

				<FooterRed 
					className={count >= 5 && count <= 9 ? "show" 
						: count === 10 ? "out"
						: "hide"
					}
				>
				</FooterRed>

				<FooterTitleBlack 
					className={count >= 6 && count <= 9 ? "show" 
						: count === 10 ? "out"
						: "hide"
					}
				>
					Free Initial Consultation
				</FooterTitleBlack>
			</FooterHeader>
		);
	}
	
};

export default FooterTitleLoop;