/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import { createGlobalStyle } from "styled-components"
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';

import Header from "./header"
import Footer from "./footer"
// import "./layout.css"

const theme = {
	red: '#FE0702',
	grey: '#9A9194',
	black: '#000',
	maxWidth: '1000px',
	radius: '0.8rem',
	shadow:' 1px 1px 50px 25px rgba(213,213,213,0.3)',
	mobileThreshold: 'max-width: 600px',
	trans: 'all 0.35s ease-in-out'
};

const Main = styled.main`
	flex:1;
	/* border:solid 1px yellow; */
	margin: 0 auto;
	/* max-width: 960px; */
	width:100%;
	padding: 0 2rem 1.45rem;
`;
const BodyContainer = styled.div`
	display:flex;
	/* border:solid 1px yellowgreen; */
	flex-direction:column;
	min-height:100vh;
`;
const GlobalStyle = createGlobalStyle`
	html {
    box-sizing: border-box;
    font-size: 10px;
		height:100vh;
  }
  *, *:before, *:after {
		box-sizing: border-box;
		border: 0 solid #d2d6dc;
	}
  body {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		background-color:${theme.black};
		color:#fff;
		min-height:100vh;
		/* height:100%; */
		overflow-y:scroll;
	
	}
  a {
    text-decoration: none;
    color: #fff;
  }


	::-webkit-scrollbar-track
	{
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			background-color: ${theme.black};
	}

	::-webkit-scrollbar
	{
			width: 10px;
			background-color: ${theme.black};
	}

	::-webkit-scrollbar-thumb
	{
    background-color: ${theme.red};
    
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .02)),
                       color-stop(.5, transparent), to(transparent)); 
	}
  /* body {
    color: ${props => (props.theme === "purple" ? "purple" : "red")};
  } */
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={ theme }>
			<GlobalStyle theme={theme} />
			<BodyContainer>
				<Header siteTitle={data.site.siteMetadata.title} />
        <Main>{children}</Main>
        <Footer />
			</BodyContainer>
      
      {/* </div> */}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
